import React from "react"
import styles from "./seccion4.module.scss"

const Seccion4 = () => {
  const provincias = [
    {
      id: 1,
      nombre: "Buenos Aires",
    },
    {
      id: 2,
      nombre: "Catamarca",
    },
    {
      id: 3,
      nombre: "Chaco",
    },
    {
      id: 4,
      nombre: "Chubut",
    },
    {
      id: 5,
      nombre: "Córdoba",
    },
    {
      id: 6,
      nombre: "Corrientes",
    },
    {
      id: 7,
      nombre: "Entre Ríos",
    },
    {
      id: 8,
      nombre: "Formosa",
    },
    {
      id: 9,
      nombre: "Jujuy",
    },
    {
      id: 10,
      nombre: "La Pampa",
    },
    {
      id: 11,
      nombre: "La Rioja",
    },
    {
      id: 12,
      nombre: "Mendoza",
    },
    {
      id: 13,
      nombre: "Misiones",
    },
    {
      id: 14,
      nombre: "Neuquén",
    },
    {
      id: 15,
      nombre: "Río Negro",
    },
    {
      id: 16,
      nombre: "Salta",
    },
    {
      id: 17,
      nombre: "San Juan",
    },
    {
      id: 18,
      nombre: "San Luis",
    },
    {
      id: 19,
      nombre: "Santa Cruz",
    },
    {
      id: 20,
      nombre: "Santa Fe",
    },
    {
      id: 21,
      nombre: "Santiago del Estero",
    },

    {
      id: 22,
      nombre: "Tierra del Fuego",
    },
    {
      id: 23,
      nombre: "Tucumán",
    },
  ]

  return (
    <>
      <div className={styles.seccion4}>
        <div className={styles.container}>
          <div className={styles.container1}>
            <h2>Suscribite para recibir alertas</h2>
            <p>
              Te enviaremos un email en caso de alta probabilidad de incendios y
              recibirás consejos acerca de cómo cuidar los espacios verdes.
            </p>
          </div>

          <form /* action='/' method='post' onSubmit={submitChange} */ >
            <div className={styles.container2}>
              <input
                className="inputform"
                type="text"
                name="nombre"
                placeholder="Nombre*"
              />
              <input
                className="inputform"
                type="text"
                name="apellido"
                placeholder="Apellido*"
              />
              <input
                className="inputform"
                type="email"
                name="email"
                placeholder="Email*"
                /*  value={loginUser.email}
                    onChange={emailChange} */
              />
              <select
                className="inputform"
                type="text"
                name="provincia"
                id="provincia"
              >
                "Provincia*"
             {/*    <option value={name}>{name}</option> */}
              </select>
              <div className={styles.inputcheckform} >
              <input type="radio" id="ok" value="ok"
              name="ok"/>
                He leído y acepto los {''}
                <span className={styles.color}> {''}términos y condiciones </span>
              </div>
              <button className={styles.button}>
                Suscribirme a las alertas
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default Seccion4
