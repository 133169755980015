import React from "react"
import styles from "./seccion1.module.scss"
import imagenProbAlta from "../../../images/incendios/seccion1/alta.svg"
/* import imagenProbMedia from "../../../images/incendios/seccion1/media.svg"
import imagenProbBaja from "../../../images/incendios/seccion1/baja.svg" */

const temperaturas = [
  {
    id: 1,
    img:  imagenProbAlta ,
    text: "Alta",
  },
  /*   {
    id: 2,
    img:  imagenProbMedia ,
    text: "Media",
  },
  {
    id: 3,
    img:  imagenProbBaja ,
    text: "Baja",
  }, */
]

const Seccion1 = () => {
  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.container}>
          {temperaturas.map(({ id, text, img}) => (
            <div className={styles.container1}key={id}>
              <p className={styles.p1}>Probabilidad de incendio</p>
              <p className={styles.p2}>{text}</p>
              <p className={styles.p3}>Semana 01/02 al 07/02 </p>
              <p className={styles.p4}>
                El índice no reemplaza a las mediciones oficiales sino que busca
                concientizar sobre la acción humana en el 95% de los incendios
                forestales.
              </p>
          {/*     <img
                className={styles.img}
                srl={img}
                alt={img}
                width="552px"
                height="280px"
                transform='rotate(-90deg)'
              />  */}
            </div>
          ))}
          <div>
          <div className={styles.container2}>
          <h3>DESARROLLADO POR CERVEZA PATAGONIA</h3>
        </div>
          </div>
       
        </div>
       
       
      </div>
    </>
  )
}
export default Seccion1
