import React from "react"
import styles from "./seccion3.module.scss"
import imagen from "../../../images/incendios/seccion3/IMG_LRG.svg"

const Seccion3 = () => {
  return (
    <>
      <div className={styles.seccion3}>
        <div className={styles.container1}>
          <div className={styles.img}>
          <img src={imagen} alt="Foto Paisaje" />
          </div>
          <div className={styles.container2}>
            <div className={styles.container2_1}>
              <h3>
                El 95% de los incendios son causados por personas
              </h3>
              <p>
                Hicimos un algoritmo que crea conciencia sobre este dato,
                estimando la cantidad de incendios en base a: cantidad de
                turistas en Bariloche, cantidad de incendios en Río Negro,
                temperatura y precipitación por mes de la Ciudad de Bariloche.
              </p>
            </div>
            <div className={styles.container2_2}>
            <h3>POR QUÉ LO HACEMOS</h3>
            <p>
              El propósito de Cerveza Patagonia es cuidar y proteger el lugar
              que nos rodea y nos inspira todos los días. Entre todos podemos
              evitar 9,5 de cada 10 incendios.
            </p>
            
            </div>
          
          </div>
        </div>
        <div className={styles.container3}>
          <h1>Si lo podemos predecir, lo podemos prevenir.</h1>
        </div>
      </div>
    </>
  )
}
export default Seccion3
