import React from "react"
import Navbar from '../components/incendios/Navbar/'
import Seccion1 from '../components/incendios/Seccion1/'
import Seccion2 from '../components/incendios/Seccion2/'
import Seccion3 from '../components/incendios/Seccion3/'
import Seccion4 from '../components/incendios/Seccion4/'
import Footer from '../components/incendios/Footer/'

export default class incendios extends React.Component {

  render() {
    return (
      <>
        <Navbar />
        <Seccion1 />
        <Seccion2 />
        <Seccion3 />
        <Seccion4 />
        <Footer />
      </>
    )
  }
}