import React from "react"
import styles from "./navbar.module.scss"

const Navbar = () => {
  return (
    <>
      <div className={styles.navbar}>
      <div className={styles.container}>
          <div className={styles.container1}>
            <div>
              <h1>LUGAR</h1>
            </div>
            <div>
              <p>San Carlos de Bariloche</p>
              <p>Río Negro - Argentina </p>
            </div>
          </div>
        </div>
        <div className={styles.container1}>
          <div>
            <h1>FECHA</h1>
          </div>
          <div>
            <p>date</p>
            <p>hour</p>
          </div>
        </div>
      </div>
   
    </>
  )
}

export default Navbar
