import React from "react"
import styles from "./seccion2.module.scss"
/* import iconoFuego from '../../../images/incendios/seccion2/fuego'
import iconoCigarrillo from '../../../images/incendios/seccion2/cigarrilo' */
import iconoCesto from "../../../images/incendios/seccion2/cesto.svg"
import iconoCelular from "../../../images/incendios/seccion2/celular.svg"

const sections = [
  {
    id: 1,
    /* img: iconoFuego , */
    text:
      "No hacer fuego en bosques, playas, debajo de los árboles o en cualquier lugar no habilitado.",
  },
  {
    id: 2,
    /*   img: iconoCigarrillo, */
    text:
      "Arrojar colillas de cigarrillos o fósforos en un contenedor, nunca en el piso.",
  },
  {
    id: 3,
    img:  iconoCesto ,
    text:
      "Recoger todos los residuos generados y depositarlos en un lugar apropiado.",
  },
  {
    id: 4,
    img:  iconoCelular ,
    text: "Denunciar inmediatamente la existencia de fuego al ",
    colorText: "número 103.",
  },
]

const Seccion2 = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.h1}>
          <h1>¿Qué medidas podemos tomar?</h1>
        </div>
        <div className={styles.container1}>
          {sections.map(({ id, text, img, colorText }) => (
            <div className={styles.card} key={id}>
              <img src={img} alt={img} width="61.77px" height="62.49px" />
              <br />
              <p> {text} <span>{colorText}</span></p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Seccion2
