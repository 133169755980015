import React from "react"
import styles from "./footer.module.scss"

import { FaFacebookF } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import logo from "../../../images/incendios/footer/logoPatagonia.svg"

const Footer = () => {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.container} >
        <div className={styles.container1}>
          <img className={styles.logo} srl={logo} alt="Foto Logo" />
          <div className={styles.socialIcons}>
            <div className={styles.icon}>
              <a href="https://www.facebook.com/patagoniacerveza/">
                <div className={""}>
                  <FaFacebookF />
                </div>
              </a>
            </div>

            <div className={styles.icon}>
              <a href={"https://www.instagram.com/cervezapatagonia/"}>
                <div className={""}>
                  <FaInstagram />
                </div>
              </a>
            </div>

            <div className={styles.icon}>
              <a
                href={
                  "https://www.youtube.com/channel/UCkwAEzYPbHfzz9tXtOtj0cg"
                }
              >
                <div className={""}>
                  <FaYoutube />
                </div>
              </a>
            </div>
          </div>

          <div className={styles.mensaje}>
            <p>
              BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
              COMPARTA EL CONTENIDO CON MENORES. ©2021 CERVEZA PATAGONIA
            </p>
          </div>
        </div>

       
        <div className={styles.container2}>
          <div className={styles.container2_1}>
            
              <p>Visitá nuestro sitio</p>
              <div className={styles.container2_1_1} >
              <a
                href={"../../docs/TyC.PDF"}
              >
                Términos y condiciones
              </a>
              <a
                href={"../../docs/POL.PDF"}
              >
                Políticas de privacidad
              </a>

              <a
                href={"mailto:comercial@patagoniabrewingco.com"}
              >
                Franquicias
              </a>
              <a
                href={""}
              >
                Informacion Oficial de la compañia
              </a>
            </div>
          </div>
          <div className={styles.container2_2}>
            <p> Acerca de nosotros</p>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Footer
